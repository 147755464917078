@import "../../../../../../../styles/variables";

.cardRowCompanies {
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 7px 10px;
  align-items: center;
  gap: 5px;
  @include cardRowWrapper;
  &-list {
    display: grid;
    @include borderRadius;
    gap: 5px;
    align-items: center;
    display: grid;
    width: 100%;
    grid-template-columns: 
    // 80px
      3fr 2fr 1fr 2fr 2fr 2fr 1fr 100px;
  }
  &-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    padding: 1px;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-item.fullname {
    color: #c3169d;
    cursor: pointer;
  }
  &-item.dateStart {
    color: $light-green;
  }
  &-item.dateEnd {
    color: $red;
  }
  &-item.btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    overflow: visible;
    img {
      cursor: pointer;
      @include SmdBtn;
    }
  }
  &-item.number {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
  }
  &-item.name {
    @include borderRadius;
    overflow: hidden;
    align-self: center;
    > span {
      display: inline-block;
      text-align: start;
      @include cardRowPaddingSpan;
      color: $blue-active;
    }
    @include HoverBtnRow;
  }
  &-item.email {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &-item.type {
    span {
      color: $light-green;
    }
  }
  &-item.directorFIO {
    span {
      color: $blue-dark;
    }
  }
}
