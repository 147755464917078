@import "../../styles/variables";

.calendarPanel {
  display: flex;
  justify-content: center;
  &-content {
    @include flexGap(20px, row);
    align-items: center;
    @include _1300 {
      @include gapMin;
    }
    &-prev,
    &-next {
      @include flexAllCenter();
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #c0c0c0;
      @include borderRadius;
      width: 34px;
      height: 34px;
      cursor: pointer;
      @include _1300 {
        width: 30px;
        height: 30px;
      }
      @include _1024 {
        width: 40px;
        height: 40px;
      }
    }
    &-next svg {
      transform: rotate(180deg);
    }
    &-value {
      position: relative;
      min-width: 173px;
      height: 34px;
      span {
        @include FSbaseBold;
        color: white;
        height: 100%;
        display: flex;
        align-items: center;
      }
      @include _1300 {
        min-width: 150px;
        width: 150px;
        height: 30px;
      }
      @include _1024 {
        height: 40px;

        display: flex;
        align-items: center;
      }
      &-title {
        @include flexAllCenter();
        @include gapMin;
        background: $blue;
        @include borderRadius;
        padding: 7px 10px;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        @include _1024 {
          width: 100%;

          padding: 10px;
        }
      }
      &-menu {
        display: flex;
        justify-content: center;
        gap: 12px;
        position: absolute;
        bottom: -305px;
        left: 0;
        background: #04488e;
        @include borderRadius;
        padding: 8px 8px;
        min-width: 173px;
        width: 100%;
        height: 300px;
        z-index: 10;
        @include _1300 {
          min-width: 150px;
          width: 150px;
        }
        &-month,
        &-year {
          @include flexGap(5px, col);
          text-align: center;
          max-height: 300px;
          span {
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
              color: $main-color;
            }
          }
          span.active {
            color: $main-color;
          }
          /* Скрываем scrollbar для Chrome, Safari и Opera */
          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none; /* IE и Edge */
          scrollbar-width: none;
        }
        &-year {
          padding-right: 5px;
        }
      }
    }
  }
}
