@import "src/styles/variables";

.reports {
  padding: 20px;
  background: #ffffff;
  @include borderRadius;
  border: 1px solid $grey;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  @include gapMin;
  @include _1700 {
    padding: 10px;
    // height: 630px;
  }
  &-title {
    font-family: "Montserrat";
    @include FSh1;
    color: black;
    @include _1024 {
      @include FStitleM;
    }
  }
  &-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    @include gapBig;
    &-modal {
      background: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      @include borderRadius;
      padding: 14px 20px;
      width: 327px;
      position: absolute;
      top: 0px;
      right: 0;
      display: flex;
      flex-direction: column;
      @include gapMin;
      z-index: 1;
      &-btns {
        display: flex;
        @include gapMin;
      }
    }
  }
  &-content {
    display: grid;
    @include gapBig;
    // align-items: center;
    grid-template-columns: 700px 1fr;
    @include _1700 {
      grid-template-columns: 500px 1fr;
    }
    @include _1024 {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }

    &-activeZone {
      display: flex;
      flex-direction: column;
      @include gapBig;
      align-items: flex-start;
    }
    &-cards {
      @include gapMin;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // align-items: flex-start;
      // height: 100%;
      @include _1024 {
        grid-template-columns: repeat(3, 1fr);
        height: fit-content;
      }
      @include _768 {
        grid-template-columns: repeat(2, 1fr);
      }
      @include _600 {
        grid-template-columns: 1fr;
      }
      &-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include gapMin;
        background: white;
        border: 1px solid $grey;
        @include borderRadius;
        padding: 10px;
        width: 100%;
        transition: all 0.3s;
        &.atWork.activeCard {
          background-color: #04498e1e;
        }
        &.onVerification.activeCard {
          background-color: #dc9e001f;
        }
        &.assigned.activeCard {
          background-color: #8000ff1c;
        }
        &.rejected.activeCard {
          background-color: #c0c0c01b;
        }
        &.overdue {
          color: $overdue;
        }
        &.completed.activeCard {
          background-color: #16c35b18;
        }
        &-title {
          font-family: "Montserrat";
          @include FSbaseBold;

          &.atWork {
            color: $atWork;
          }
          &.onVerification {
            color: $onVerification;
          }
          &.assigned {
            color: $assigned;
          }
          &.rejected {
            color: $grey-dark;
          }
          &.overdue {
            color: $overdue;
          }
          &.completed {
            color: $completed;
          }
        }
        &-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          &-count {
            position: relative;

            display: flex;
            @include gapMin;
            align-items: center;
            color: $blue;

            &-box {
              display: flex;
              flex-direction: column;
              @include gapMin;
              &-text {
                @include FSbaseBold;
                cursor: pointer;
                @include HoverBtnRow;
                padding: 4px;
                @include borderRadius;
                &:last-child {
                  color: $overdue;
                }
              }
            }
            &-value {
              // @include FSh1;
              font-size: 30px;
              @include HoverBtnRow;
              cursor: pointer;

              @include borderRadius;
              padding: 10px;
              @include _1700 {
                padding: 5px;
              }
              @include _1024 {
                padding: 10px;
              }
            }
            &-procent {
              font-family: "Montserrat";
              @include FSbaseBold;
              position: absolute;
              top: 5px;
              right: -30px;
              &-title {
                @include FSsubtitle;
              }
            }
          }
        }
        // &.success {
        //   background-color: #16c35b39;
        // }
        // &:hover {
        //   background-color: $blue-m-bg;
        // }
      }
    }
    &-progress {
      // flex-basis: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      position: relative;
      width: fit-content;
      width: 100%;
      height: 350px;
      @include _1700 {
        height: 260px;
      }
      @include _1024 {
        height: 300px;
      }
      &-wrapper {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
      }
      &-title {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: #000000;
        white-space: nowrap;
      }
      &-count {
        position: relative;

        &-value {
          @include FSh1;
        }
        &-procent {
          font-family: "Montserrat";
          @include FSbaseBold;
          position: absolute;
          top: 5px;
          right: 10%;
        }
      }
    }
  }
}

.reports-content-cards-card-content-count-procent.up,
.reports-content-progress-count-procent.up {
  color: $light-green;
}

.reports-content-cards-card-content-count-procent.down,
.reports-content-progress-count-procent.down {
  color: $red;
}

.reports-content-cards-card-content-count-procent.up::after,
.reports-content-progress-count-procent.up::after {
  content: url("../../../../assets/icons/upArrow.svg");
  position: absolute;
  top: -12px;
}

.reports-content-cards-card-content-count-procent.down::after,
.reports-content-progress-count-procent.down::after {
  content: url("../../../../assets/icons/downArrow.svg");
  position: absolute;
  top: -12px;
}
