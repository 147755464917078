@import "../../../../../styles/variables";

.companies {
  display: flex;
  flex-direction: column;
  &__searchPanel {
    padding: 0 20px 0 10px;
  }
  &__content {
    // padding-top: 20px;
  }
  &-row {
    width: 100%;
    padding: 0 10px 0 10px;
    height: calc(100vh - 300px);
    overflow-y: scroll;
    overflow-x: hidden;
    @include _2000 {
      height: calc(100vh - 290px);
    }
    @include _1700 {
      height: calc(100vh - 210px);
    }
    @include _1300 {
      height: calc(100vh - 200px);
    }
    &__header {
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: grid;
      gap: 10px;
      align-items: center;
      font-family: "Montserrat";
      grid-template-columns:
        3fr
        2fr
        1.5fr
        2fr
        2fr
        1fr
        1.5fr
        80px;
      @include _1700 {
        grid-template-columns:
          3fr
          2fr
          1.5fr
          2fr
          2fr
          1fr
          1.5fr
          60px;
      }
      @include headerWrapper;
    }
    &-header {
      padding-bottom: 10px;
      &__item {
        display: flex;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: center;
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          vertical-align: middle;
          height: auto;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__item.numder-item {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        @include gapBig;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
  &-grid {
    height: calc(100vh - 300px);
    @include _2000 {
      height: calc(100vh - 290px);
    }
    @include _1700 {
      height: calc(100vh - 210px);
    }
    @include _1300 {
      height: calc(100vh - 200px);
    }
    &-list {
      padding: 0 20px 0 10px;
      @include cardColumnGrid;
    }
  }
  &-pagination {
    padding-top: 20px;
    @include _1700 {
      padding-top: 10px;
    }
  }
}
