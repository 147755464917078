@import "src/styles/variables";

.calendarMini {
  @include flexGap(10px, col);
  padding: 10px;
  background: #ffffff;
  @include borderRadius;
  width: 100%;
  max-width: 275px;
  height: fit-content;
  border: 1px solid $grey;
  span {
    font-family: "Montserrat";
  }
  @include _1024 {
    max-width: 100%;
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  @include _768 {
    display: flex;
    flex-direction: column;
  }
  &-btnAddEvent {
    @include flexAllCenter();
    color: white;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    width: 32px;
    height: 28px;
    background: #0094e7;
    border: 1px solid $grey;
    @include borderRadius;
  }
  &-title {
    @include flexGap(5px, row);
    .year {
      @include FSh1;
      color: #04488e;
    }
    .month {
      font-weight: 400;
      @include FSh1;
      color: #7bc7f1;
    }
  }
  &-calendar {
    display: flex;
    flex-direction: column;
    @include gapMin;
    @include _768 {
      width: 100%;
      @include gapBig;
      border: 1px solid $grey;
      @include borderRadius;
      padding: 10px;
    }
    &-header {
      display: grid;
      grid-template-columns: repeat(7, minmax(auto, 38px));
      text-align: center;
      @include _768 {
        padding-top: 10px;
        grid-template-columns: repeat(7, minmax(auto, 1fr));
      }
      > span {
        font-family: "Montserrat";
        @include FSmdSemiBold;
        line-height: 16px;
        color: $grey;
        @include _768 {
          @include FSbaseRegM;
          color: $blue;
          &:nth-last-child(-n + 2) {
            color: $red;
          }
        }
      }
    }

    &-main {
      @include flexGap(15px, col);
      @include _1300 {
        @include gapMin;
      }
      @include _768 {
        @include gapBig;
      }
      &-week {
        display: grid;
        grid-template-columns: repeat(7, minmax(auto, 38px));
        @include _768 {
          grid-template-columns: repeat(7, minmax(auto, 1fr));
        }
      }
      &-day {
        text-align: center;
        position: relative;
        span {
          font-variant-numeric: tabular-nums;
          -moz-font-feature-settings: "tnum";
          -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
        }

        &-title {
          font-weight: 600;
          font-size: 11px;
          z-index: 1;
          cursor: pointer;
          @include _768 {
            @include FSbaseRegM;
          }
        }
        &-title.noMonth {
          color: $grey;
        }
      }
      &-day.activeDay {
        cursor: pointer;
        .title {
          color: white;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 1px solid $main-color;
          @include _1300 {
            width: 25px;
            height: 25px;
          }
        }
        &::before {
          content: "";
          position: absolute;
          bottom: -20%;
          left: 50%;
          transform: translateX(-50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: white;
        }
      }
      &-day.haveEvents {
        cursor: pointer;
        ::after {
          content: "";
          position: absolute;
          bottom: -20%;
          left: 50%;
          transform: translateX(-50%);
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $main-color;
        }
      }
      &-day.currentDay {
        cursor: pointer;
        .title {
          color: white;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 1px solid $grey;
          @include _1300 {
            width: 25px;
            height: 25px;
          }
        }
        &::before {
          content: "";
          position: absolute;
          bottom: -20%;
          left: 50%;
          transform: translateX(-50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: white;
        }
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      span {
        font-size: 13px;
      }
      .bold {
        font-weight: 700;
        font-size: 13px;
      }
    }
  }

  .hr {
    width: 100%;
    height: 1px;
    background-color: #f6f6f6;
    @include borderRadius;
  }
}
