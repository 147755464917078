@import "../../../../../../../styles/variables";

.groupsRow {
  @include cardRowWrapper;
  &__list {
    list-style-type: none;
    @include borderRadius;
    gap: 10px;
    display: flex;
    align-items: center;
    display: grid;
    width: 100%;
    grid-template-columns:
      // 80px

      2fr
      2fr
      // 2fr
      2fr
      100px;
  }
  &__item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    padding: 1px;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-number {
      display: flex;
      @include gapBig;
      align-items: center;
      justify-content: flex-start;
    }
    &-manager {
      color: $blue-dark;
    }
    &-group {
      @include borderRadius;
      overflow: hidden;
      align-self: center;
      > span {
        display: inline-block;
        text-align: start;
        color: $blue-active;
        @include cardRowPaddingSpan;
      }
      @include HoverBtnRow;
    }
    &-сompany {
      color: $light-green;
      @include borderRadius;
      overflow: hidden;
      align-self: center;
      background-color: rgba(22, 195, 91, 0.1);
      > span {
        display: inline-block;
        text-align: start;
        @include cardRowPaddingSpan;
      }
    }

    &-btns {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;
      overflow: visible;
    }
  }

  &__btn {
    background-color: inherit;
  }
}
