@import "../../../../styles/_variables";

.companyEmployeesList {
  display: flex;
  flex-direction: column;
  @include gapBig;
  padding: 0 20px 100px 10px;
  &-header {
    @include headerWrapper;
    display: grid;
    grid-template-columns:
      2fr
      1.5fr
      2fr
      2fr
      0.8fr
      100px;
    @include _1700 {
      grid-template-columns:
        2fr
        1.5fr
        2fr
        2fr
        0.8fr
        70px;
    }
    &-item {
      display: flex;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      justify-content: center;
      > span {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: $blue;
        vertical-align: middle;
        height: auto;
        display: inline-block;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-sort {
        cursor: pointer;
      }
    }
    &-item.numder-item {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      @include gapBig;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
}
