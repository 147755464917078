@import "../../../../../../../styles/variables";

.cardRowsPartic {
  @include cardRowWrapper;
  display: grid;
  align-items: center;
  width: 100%;
  gap: 5px;
  grid-template-columns:
  //  80px
    2fr 1.5fr 1.5fr 1.5fr 1.5fr 80px;
  &-item {
    &:not(:nth-child(8)) {
      display: flex;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      justify-content: center;
    }
    span,
    a {
      font-family: "Montserrat";
      @include FSbaseBold;
      color: black;
      text-decoration: none;
      display: inline-block;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-item.number {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
  }
  &-item.btns {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    img {
      cursor: pointer;
      @include SmdBtn;
    }
  }
  &-item.role {
    max-width: 180px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    .select {
      display: flex;
      width: 100%;
    }
  }
  &-item.name {
    overflow: hidden;
    align-self: center;
    @include borderRadius;

    > span {
      display: inline-block;
      text-align: start;
      @include cardRowPaddingSpan;
      color: $blue-active;
    }
    @include HoverBtnRow;
  }
}
