// varibles
$main-color: #0094e7;
$main-color-light: #7bc7f1;
$main-color-ultra-light: #bfe9ff49;
$main-color-hover: #0070ac;
$blue: #04488e;
$blue-active: #003ad2;
$blue-dark: #04488e;
$blue-dark-btn: #04488e;
$blue-opacity: #04498e91;
$blue-light-opacity: rgba(2%, 28%, 56%, 0.1);
$blue-m-bg: #e5eaec;
$blue-m-text: #95a3ac;
$background-color: #2c2c2c;
$background-color-content: #f5f9fb;
$background-color-link: #f5f9fb;
$light-green: #16c35b;
$purple: #7f00ff;
$turquoise: #00b4d8;
$red: #db1f35;
// $grey: rgba(140, 158, 165, 0.8);
// $grey-light: rgba(140, 158, 165, 0.3);
$grey-blue-dark: #8c9ea59d;
$grey: #c0c0c0;
$grey-light: #f6f6f6;
$grey-dark: #818181;
$yellow: #f4b400;
$orange: #ff5630;
// status
$completed: #16c35b;
$atWork: #04488e;
$onVerification: #dc9d00;
$overdue: #db1f35;
$rejected: #c0c0c0;
$assigned: #7f00ff;

// mixins
@mixin borderRadius() {
  border-radius: 6px !important;
}

@mixin flexGap($gap, $direction) {
  @if $direction == col {
    display: flex;
    flex-direction: column;
    gap: $gap;
  } @else {
    display: flex;
    flex-direction: row;
    gap: $gap;
  }
}

@mixin flexConter($position) {
  display: flex;
  justify-content: $position;
}

@mixin flexAllCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin _2500 {
  @media (max-width: 2500px) {
    @content;
  }
}

@mixin min_2200 {
  @media (min-width: (2200px + 0.02)) {
    @content;
  }
}

@mixin _2200 {
  @media (max-width: 2200px) {
    @content;
  }
}

@mixin _2000 {
  @media (max-width: 2000px) {
    @content;
  }
}
@mixin min_2000 {
  @media (min-width: (2000px + 0.02)) {
    @content;
  }
}

@mixin _1900 {
  @media (max-width: 1900px) {
    @content;
  }
}

@mixin _1700 {
  @media (max-width: 1700px) {
    @content;
  }
}

@mixin _1600 {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin _1455 {
  @media (max-width: 1455px) {
    @content;
  }
}

@mixin _1300 {
  @media (max-width: 1300px) {
    @content;
  }
}
@mixin min_1300 {
  @media (min-width: (1300px + 0.02)) {
    @content;
  }
}

@mixin _1200 {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin _1100 {
  @media (max-width: 1100px) {
    @content;
  }
}
@mixin _1024 {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin min_1024 {
  @media (min-width: (1024px + 0.02)) {
    @content;
  }
}
@mixin _900 {
  @media (max-width: (900px - 0.02)) {
    @content;
  }
}
@mixin _768 {
  @media (max-width: (768px - 0.02)) {
    @content;
  }
}
@mixin min_768 {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin _480 {
  @media (max-width: (480px - 0.02)) {
    @content;
  }
}
@mixin _600 {
  @media (max-width: (600px - 0.02)) {
    @content;
  }
}
@mixin min_600 {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin min_480 {
  @media (min-width: 480px) {
    @content;
  }
}
@mixin min_400 {
  @media (min-width: 400px) {
    @content;
  }
}
@mixin _400 {
  @media (max-width: (400px - 0.02)) {
    @content;
  }
}
@mixin _350 {
  @media (max-width: (350px - 0.02)) {
    @content;
  }
}
@mixin min_350 {
  @media (min-width: 350px) {
    @content;
  }
}

@mixin cardColumnWrapper {
  font-family: "Montserrat";
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 220px;
  justify-content: space-between;
  min-height: 330px;
  position: relative;
  border: 1px solid $grey;
  @include borderRadius;
  overflow: hidden;
  @include _1700 {
    min-height: 270px;
  }
}

@mixin cardColumnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  @include gapBig;
  list-style-type: none;
  margin-bottom: 20px;
  @include _2200 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @include _1300 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 10px;
  }
  @include _1200 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@mixin cardDocColumnGrid {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  @include gapBig;
  list-style-type: none;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  justify-self: center;
  @include _2200 {
    grid-template-columns: repeat(8, 1fr);
  }
  @include _2000 {
    grid-template-columns: repeat(7, 1fr);
  }
  @include _1900 {
    grid-template-columns: repeat(6, 1fr);
  }
  @include _1700 {
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: 10px;
  }
  @include _1455 {
    grid-template-columns: repeat(5, 1fr);
  }
  @include _1200 {
    grid-template-columns: repeat(4, 1fr);
  }
  // @include _1024 {
  //   gap: 10px;
  //   grid-template-columns: repeat(4, minmax(100px, 180px));
  // }
  // @include _900 {
  //   grid-template-columns: repeat(4, minmax(100px, 180px));
  // }
  @include _768 {
    grid-template-columns: repeat(3, 1fr);
  }
  @include _600 {
    grid-template-columns: repeat(2, 1fr);
  }
  @include _480 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin cardColumnGridMobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
  @include min_480 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @include min_768 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@mixin cardColumnWrapperProject {
  display: flex;
  flex-direction: column;
  gap: 5px;
  // max-width: 405px;
  // min-width: 320px;
  width: 100%;
  background-color: white;
  // padding: 3px;
  overflow: hidden;
  position: relative;
  border: 1px solid $grey;
  @include borderRadius;
  @include _1024 {
    min-width: 100%;
  }
}

@mixin cardColumnGridProject {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @include gapBig;
  @include _2200 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @include _1700 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include _1200 {
    grid-template-columns: 1fr 1fr;
  }
}

@mixin cardRowWrapper {
  display: flex;
  font-family: "Montserrat";
  background-color: #ffffff;
  border: 1px solid $grey;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  @include borderRadius;
  @include _2000 {
    padding: 10px;
    height: 45px;
  }
  @include _1700 {
    height: 40px;
    padding: 5px 10px;
  }
}
@mixin cardRowPaddingSpan {
  padding: 8px 10px;
  height: 100%;
  @include _2000 {
    padding: 8px 8px;
  }
  @include _1700 {
    padding: 6px 8px;
  }
}

// размер шрифта в хедере
@mixin FSh1 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  @include _2000 {
    font-size: 18px;
    font-weight: 600;
  }
  @include _1700 {
    font-size: 14px;
    font-weight: 600;
  }
}
// размер шрифта сабтайтла
@mixin FSsubtitle {
  font-weight: 700;
  font-size: 16px;
  @include _2000 {
    font-size: 14px;
    font-weight: 600;
  }
  @include _1700 {
    font-size: 12px;
    font-weight: 600;
  }
}

@mixin FSsubtitleReg {
  font-weight: 400;
  font-size: 16px;
  @include _2000 {
    font-size: 14px;
    font-weight: 400;
  }
  @include _1700 {
    font-size: 12px;
    font-weight: 400;
  }
}

// размер шрифта в навбаре
@mixin FSnav {
  font-size: 16px;
  font-weight: 600;
  @include _2000 {
    font-size: 14px;
    font-weight: 600;
  }
  @include _1700 {
    font-size: 12px;
    font-weight: 600;
  }
  @include _1024 {
    font-size: 14px;
    font-weight: 500;
  }
}
// размер шрифта в кнопке
@mixin FSbtn {
  font-size: 16px;
  font-weight: 600;
  @include _2000 {
    font-size: 14px;
    font-weight: 500;
  }
  @include _1700 {
    font-size: 12px;
    font-weight: 500;
  }
}

// размер иконки в в кнопке
@mixin SbtnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  @include _2000 {
    width: 12px;
    height: 12px;
  }
  @include _1700 {
    width: 10px;
    height: 10px;
  }
}
// размер иконки в фильтре и панеле
@mixin SFilterPanelIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  @include _2000 {
    width: 20px;
    height: 20px;
  }
  @include _1700 {
    width: 15px;
    height: 15px;
  }
}
// размер иконки в навбаре
@mixin SNavbarIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  @include _2000 {
    width: 20px;
    height: 20px;
  }
  @include _1700 {
    width: 15px;
    height: 15px;
  }
}

// размер средних иконок(применяются в row)
@mixin SmdBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  @include _2000 {
    width: 16px;
    height: 16px;
  }
  @include _1700 {
    width: 14px;
    height: 14px;
  }
}

@mixin SxlBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  @include _2000 {
    width: 20px;
    height: 20px;
  }
  @include _1700 {
    width: 16px;
    height: 16px;
  }
}

// базовый шрифт
@mixin FSbaseBold {
  font-weight: 700;
  font-size: 14px;
  @include _2000 {
    font-size: 12px;
    font-weight: 600;
  }
  @include _1700 {
    font-size: 10px;
    font-weight: 600;
  }
  @include _1024 {
    @include FSbaseBoldM;
  }
}

@mixin FSbaseReg {
  font-weight: 400;
  font-size: 14px !important;
  @include _2000 {
    font-size: 12px !important;
    font-weight: 400;
  }
  @include _1700 {
    font-size: 10px !important;
    font-weight: 400;
  }
  @include _1024 {
    @include FSbaseRegM;
  }
}

@mixin FSbaseSemibold {
  font-weight: 600;
  font-size: 14px;
  @include _2000 {
    font-size: 12px;
    font-weight: 500;
  }
  @include _1700 {
    font-size: 10px;
    font-weight: 500;
  }
}

@mixin FSstrSub {
  font-weight: 700;
  font-size: 12px;
  @include _2000 {
    font-size: 10px;
    font-weight: 600;
  }
  @include _1700 {
    font-size: 10px;
    font-weight: 600;
  }
}

@mixin FSbaseMed {
  font-weight: 500;
  font-size: 14px;
  @include _2000 {
    font-size: 12px;
    font-weight: 500;
  }
  @include _1700 {
    font-size: 10px;
    font-weight: 500;
  }
}

// размер шрифта тайтла row
@mixin FStitleRow {
  font-weight: 700;
  font-size: 12px;
  @include _2000 {
    font-weight: 600;
    font-size: 10px;
  }
}

@mixin FSmdMed {
  font-weight: 500;
  font-size: 12px;
  @include _2000 {
    font-weight: 500;
    font-size: 10px;
  }
  @include _1700 {
    font-weight: 500;
    font-size: 10px;
  }
  @include _1024 {
    font-size: 12px;
  }
}

@mixin FSmdSemiBold {
  font-size: 12px;
  font-weight: 600;
  @include _2000 {
    font-size: 10px;
  }
  @include _1700 {
    font-size: 10px;
  }
}

@mixin FSmdReg {
  font-weight: 400;
  font-size: 12px;
  @include _2000 {
    font-weight: 400;
    font-size: 12px;
  }
  @include _1700 {
    font-weight: 400;
    font-size: 10px;
  }
  @include _1200 {
    font-weight: 400;
    font-size: 10px;
  }
}
@mixin FSmdBold {
  font-weight: 700;
  font-size: 14px;
  @include _2000 {
    font-weight: 600;
    font-size: 12px;
  }
  @include _1700 {
    font-weight: 600;
    font-size: 10px;
  }
  @include _1200 {
    font-weight: 600;
    font-size: 12px;
  }
}

@mixin FSxsReg {
  font-weight: 400;
  font-size: 12px;
  @include _2000 {
    font-size: 10px;
  }
}

@mixin FSxsBold {
  font-weight: 700;
  font-size: 12px;
  @include _2000 {
    font-weight: 600;
    font-size: 10px;
  }
}

@mixin FSxxsReg {
  font-weight: 400;
  font-size: 10px;
  @include _2000 {
    font-size: 8px;
  }
}

// мобильные шрифты
@mixin FStitleM {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  @include _1700 {
    font-size: 14px;
  }
  @include _1024 {
    font-size: 16px;
  }
}
@mixin FSsubtitleM {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

@mixin FSbaseBoldM {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  @include min_768 {
    font-size: 14px;
    font-weight: 600;
  }
}

@mixin FSbaseRegM {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px !important;
  @include min_768 {
    font-size: 14px !important;
  }
}

@mixin FSxsRegM {
  font-weight: 400;
  font-size: 12px;
  @include min_768 {
    font-size: 10px;
  }
}

@mixin FSxsBoldM {
  font-weight: 700;
  font-size: 12px !important;
  @include min_768 {
    font-weight: 600;
    font-size: 10px !important;
  }
}

//animate__animated animate__fadeIn modal
@mixin HoverLink {
  opacity: 1;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.8;
  }
}

@mixin HoverModalText {
  span {
    display: inline-block;
    color: #0000;
    overflow: hidden;
    cursor: pointer;
    background: linear-gradient(to right, #04488e, #04488e 50%, black 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    background-position: 100%;
    transition: background-position 0.5s ease;
    text-decoration: none; // text decorations are clipped in WebKit browsers
    &:hover {
      background-position: 0 100%;
    }
  }
}

@mixin HoverFilterPanelText {
  span {
    display: inline-block;
    color: #0000;
    overflow: hidden;
    cursor: pointer;
    background: linear-gradient(to right, black, black 50%, #c0c0c0 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    background-position: 100%;
    transition: background-position 0.5s ease;
    text-decoration: none; // text decorations are clipped in WebKit browsers
    &:hover {
      background-position: 0 100%;
    }
  }
}
@mixin HoverSearchPanelText {
  span {
    display: inline-block;
    color: #0000;
    overflow: hidden;
    cursor: pointer;
    background: linear-gradient(to right, #04488e, #04488e 50%, black 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    background-position: 100%;
    transition: background-position 0.5s ease;
    text-decoration: none; // text decorations are clipped in WebKit browsers
    &:hover {
      background-position: 0 100%;
    }
  }
}

@mixin HoverBtnBlue {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #0070ac, #0070ac 50%, #0094e7 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
    svg {
      path {
        fill: #fff !important;
      }
    }
  }
}

@mixin HoverBtnRed {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #b31426, #b31426 50%, #db1f35 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
  }
}

@mixin HoverBtnDark {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #032e5a, #032e5a 50%, #04488e 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
  }
}

@mixin HoverBtnYellow {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #bd8b02, #bd8b02 50%, #f4b400 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
  }
}

@mixin HoverBtnGreen {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #119b48, #119b48 50%, #16c35b 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
  }
}

@mixin HoverBtnPurple {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #550d9e, #550d9e 50%, #7f00ff 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
  }
}

@mixin HoverBtnDarkGrey {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #899297, #899297 50%, #95a3ac 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
  }
}

@mixin HoverBtnRow {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #b2d1e2, #b2d1e2 50%, #0159a133 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
  }
}
@mixin HoverBtnSearch {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #04488e, #04488e 50%, #fff 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  > span {
    color: #04488e;
    transition: color 0.4s ease;
  }
  &:hover {
    background-position: 0 100%;
    > span {
      color: #fff;
    }
  }
}
@mixin HoverBtnSearchSelect {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #04488e, #04488e 50%, #fff 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers

  // color: #04488e;
  > option {
    > span {
      color: #04488e;
      transition: color 0.4s ease;
    }
  }

  &:hover {
    background-position: 0 100%;
    color: #fff;
    > option {
      > span {
        color: #fff;
      }
    }
  }
}
@mixin HoverBtnFolder {
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  background: linear-gradient(to right, #04488e, #04488e 50%, #fff 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  > span {
    color: #04488e;
    transition: color 0.4s ease;
  }
  svg {
    color: #04488e;
    fill: color 0.7s ease;
  }
  &:hover {
    background-position: 0 100%;
    > span {
      color: #fff;
    }
    svg {
      fill: #ffffff;
    }
  }
}

@mixin HoverBtnAnal {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #04488e, #04488e 50%, #fff 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  > span {
    color: #04488e;
    transition: color 0.4s ease;
  }
  svg {
    fill: #04488e;
    transition: fill 0.4s ease;
  }
  &:hover {
    background-position: 0 100%;
    > span {
      color: #fff;
    }
    svg {
      fill: #fff;
    }
  }
}

@mixin HoverAuthLink {
  a {
    display: flex;
    color: #ffff;
    overflow: hidden;
    cursor: pointer;
    background: linear-gradient(to right, #ffff, #ffff 50%, rgba(255, 255, 255, 0.829) 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    background-position: 100%;
    transition: background-position 0.5s ease;
    text-decoration: none; // text decorations are clipped in WebKit browsers
    > svg {
      color: rgba(255, 255, 255, 0.829);
      fill: color 0.7s ease;
    }
    &:hover {
      background-position: 0 100%;
      > span {
        color: #ffff;
      }
      svg {
        fill: #ffff;
      }
    }
  }
}

@mixin HoverTaskRow {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #b2d1e2, #b2d1e2 50%, #ffffff 50%);
  background-size: 200% 200%;
  border: 1px solid inherit;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
  }
}

@mixin HoverSelectRow {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #8ddff373, #8ddff373 50%, #ffffff 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  &:hover {
    background-position: 0 100%;
  }
  @include _1024 {
    background-position: 0 0;
    background: #fff;
    &:active {
      border: 1px solid $main-color;
    }
  }
}

@mixin HoverBtnLayer {
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  background: linear-gradient(to right, #04488e, #04488e 50%, #fff 50%);
  background-size: 200% 200%;
  background-position: 100%;
  transition: background-position 0.5s ease;
  text-decoration: none; // text decorations are clipped in WebKit browsers
  span {
    color: #04488e;
    transition: color 0.4s ease;
  }
  svg {
    color: #04488e;
    fill: color 0.7s ease;
  }
  &:hover {
    background-position: 0 100%;
    div {
      span {
        color: #fff;
      }
    }

    svg {
      fill: #ffffff;
    }
  }
}

@mixin HoverIcon {
  transition: transform 0.3s 0.1s;
  transform: scale(1);
  &:hover {
    transform: scale(1.1);
  }
}

@mixin LGavatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  @include _1700 {
    width: 50px;
    height: 50px;
  }
}

@mixin XSavatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  @include _2000 {
    width: 40px;
    height: 40px;
  }

  @include _1700 {
    width: 30px;
    height: 30px;
  }
}
@mixin AvatarM {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

@mixin ProfileAvatar {
  object-fit: cover;
  @include borderRadius;
  width: 160px;
  height: 160px;
  @include _1024 {
    width: 160px;
    height: 160px;
  }
}

@mixin animate_pulse {
  @keyframes animate_pulse {
    0% {
      box-shadow: 0 0 1px 7px rgba(#7bc7f1, 0.1), 0 0 1px 10px rgba(#7bc7f1, 0.07),
        0 0 1px 12px rgba(#7bc7f1, 0.05);
    }
    // 70% {
    //   box-shadow: 0 0 0 8px rgba(#04488e, 0.04);
    // }
    100% {
      box-shadow: 0 0 0 0 rgba(#04488e, 0.2);
    }
  }
}

@mixin animate_bg_auth {
  @keyframes bgImage {
    0% {
      opacity: 0;
      background-image: url("../assets/images/pages/auth/background-form.jpg");
      transform: scale(1);
    }
    15% {
      opacity: 1;
      background-image: url("../assets/images/pages/auth/background-form.jpg");
    }
    29% {
      opacity: 0;
      background-image: url("../assets/images/pages/auth/background-form.jpg");
      transform: scale(1.1);
    }
    30% {
      opacity: 0;
      background-image: url("../assets/images/pages/auth/background-form.jpg");
      transform: scale(1.1);
    }
    31% {
      opacity: 0;
      background-image: url("../assets/images/pages/auth/background-form2.jpg");
      transform: scale(1.1);
    }
    45% {
      opacity: 1;
      background-image: url("../assets/images/pages/auth/background-form2.jpg");
    }
    60% {
      opacity: 0;
      background-image: url("../assets/images/pages/auth/background-form2.jpg");
      transform: scale(1);
    }
    61% {
      opacity: 0;
      background-image: url("../assets/images/pages/auth/background-form3.jpg");
      transform: scale(1);
    }
    75% {
      opacity: 1;
      background-image: url("../assets/images/pages/auth/background-form3.jpg");
    }
    97% {
      opacity: 0;
      background-image: url("../assets/images/pages/auth/background-form3.jpg");
      transform: scale(1.1);
    }
    100% {
      opacity: 0;
      background-image: url("../assets/images/pages/auth/background-form3.jpg");
    }
  }
}

@mixin nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin skeletons {
  animation: wave-squares 10s infinite ease-out;
  background: linear-gradient(
    to right,
    rgba(178, 209, 226, 0.2) 8%,
    rgba(178, 209, 226, 0.5) 18%,
    rgba(178, 209, 226, 0.2) 33%
  );
}

@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.hr {
  background: $main-color-light;
  @include borderRadius;
  height: 1px;
  width: 100%;
}

@mixin headerRow {
  padding: 5px 20px 5px 10px;
}

@mixin gapBig {
  gap: 20px;
  @include _1700 {
    gap: 10px;
  }
}
@mixin gapMin {
  gap: 10px;
  @include _1700 {
    gap: 5px;
  }
}

@mixin headerWrapper {
  padding: 5px 20px 5px 10px;
}
