@import "./src/styles/variables";
.taskColumnCard {
  display: flex;
  flex-direction: column;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 10px;
  background-color: #fff;
  @include gapMin;
  position: relative;
  &:hover {
    border: 1px solid $main-color;
    transition: all 0.3s;
  }
  @include _1300 {
    padding: 5px;
  }
  &-modal {
    position: absolute;
    right: 0;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    @include borderRadius;
    // min-width: 200px;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 5;
    > span {
      background-color: #fff;
      font-family: "Montserrat";
      font-style: normal;
      flex-wrap: nowrap;
      cursor: pointer;
      @include FSmdMed;
      line-height: 14px;
      text-align: start;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include HoverModalText;
  }
  &-header {
    display: flex;
    align-items: center;
    // height: 35px;
    width: 100%;
    gap: 5px;
    &:hover {
      cursor: pointer;
    }
    @include _1700 {
      gap: 1px;
    }
    // @include _1300 {
    //   padding-bottom: 5px;
    //   height: 35px;
    // }
    &-title {
      @include borderRadius;
      overflow: hidden;
      align-self: center;
      cursor: pointer;
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include cardRowPaddingSpan;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2px;
      min-height: 30px;
      > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        text-align: start;

        color: $blue-active;
      }
      @include HoverBtnRow;
    }
    &-menu {
      display: flex;
      align-items: flex-end;
      position: relative;
      margin-right: -5px;
      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        background-color: #fff;
        img {
          @include SmdBtn;
        }
      }
    }
  }
  &-commentsCount {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-color;
    color: #fff;
    min-width: 16px;
    height: 16px;
    padding: 2px;
    @include FSmdReg;
    border-radius: 50%;
  }
  &-title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseBold;
    line-height: 14px;
    color: $blue;
  }
  &-text {
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseReg;
    line-height: 14px;
  }
  &-center {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include _1455 {
      padding: 5px 0;
    }
    &-wrapper {
      display: flex;
      gap: 5px;
      overflow: hidden;
    }
    &-avatar {
      @include XSavatar;
      flex-shrink: 0;
    }
    &-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      // width: 90%;
      // flex-shrink: 1;
      white-space: nowrap;
      // overflow: hidden;
      text-overflow: ellipsis;
    }
    &-name {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      // max-width: 99%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-position {
      font-family: "Montserrat";
      font-style: normal;
      @include FSmdMed;
      line-height: 16px;
      max-width: 99%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-box {
      display: flex;
      gap: 5px;
      align-items: center;
      // @include _1455 {
      //   flex-direction: column;
      //   width: 100%;
      //   align-items: flex-start;
      // }
      @include _1024 {
        flex-direction: row;
      }
      &.day {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      span {
        @include FSbaseBold;
      }
      &-period {
        display: flex;
        align-items: center;
        &-startDate {
          color: $light-green;
        }

        &-endDate {
          color: $red;
        }
      }
      &-day {
        display: flex;
        align-items: center;
        &.atWork {
          // background-color: #04498e19;
          height: 100%;
          align-items: center;
          // @include borderRadius;
          span {
            color: $atWork;
          }
        }
        &.overdue {
          // background-color: #db1f3516;
          height: 100%;
          align-items: center;
          // @include borderRadius;
          span {
            color: $overdue;
          }
        }
      }
    }
  }
  &-footer {
    position: relative;
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 6px;
    }
    &-avatars {
      display: flex;
    }
    &-avatar {
      @include XSavatar;
      border: 1px solid #fff;
      &:not(:first-child) {
        margin-left: -15px;
        @include _1024 {
          margin-left: -16px;
        }
      }
    }
    &-other {
      @include XSavatar;
      background: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -15px;
      border: 1px solid $grey-dark;
      // height: 100%;
      @include _1024 {
        margin-left: -16px;
      }
      > span {
        font-family: "Montserrat";
        font-style: normal;
        line-height: 17px;
        color: $grey-dark;
        @include FSbaseMed;
      }
    }
  }
}
