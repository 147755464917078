@import "src/styles/variables";

.map {
  padding: 20px;
  background: #ffffff;
  @include borderRadius;
  border: 1px solid $grey;
  @include _1024 {
    padding: 10px;
  }
  &-title {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
  &-content {
    display: flex;
    justify-content: space-between;
    @include _768 {
      flex-direction: column;
    }
    &-worldMap {
      img {
        width: 100%;
      }
    }
    &-projectslist {
      display: flex;
      flex-direction: column;
      @include gapMin;
      width: 100%;
      max-width: 406px;
      @include _768 {
        max-width: 100%;
      }
      &-title {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: $blue;
        padding-left: 20px;
      }
      &-content {
        display: flex;
        flex-direction: column;
        max-height: 720px;
        gap: 4px;
        overflow-y: auto;
        &-hr {
          display: flex;
          justify-content: flex-end;
          hr {
            width: 100%;
            max-width: 386px;
            margin-top: 4px;
            background-color: black;
            @include _768 {
              max-width: 100%;
            }
          }
        }
      }
    }
    &-worldMap {
      position: relative;
      @include _1024 {
        margin: 0 0 40px 0;
      }
      @include _600 {
        margin: 0;
      }
    }
  }
}
