@import "../../styles/_variables.scss";

@keyframes rot {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
  }
}
@keyframes rotRev {
  0% {
    opacity: 0;
    transform: rotate(90deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}

.referenceItem {
  width: 100%;
  &-panel {
    background-color: #ffffff;
    border: 1px solid $grey;
    @include borderRadius;
    padding: 10px;
    font-family: "Montserrat";
    font-style: normal;
    @include _1700 {
      padding: 7px 10px 7px 10px;
    }
    &:not(:last-child) {
      margin-bottom: 3px;
    }
    &[aria-expanded="true"] {
      .referenceItem-panel__label:before {
        transform: rotate(0deg);
      }
    }
    &[aria-expanded="true"] {
      .referenceItem-panel__label {
        color: #000000;
        > i {
          transform: rotate(180deg);
        }
      }
    }
    &[aria-expanded="true"] {
      .referenceItem-panel__content {
        opacity: 1;
        display: block;
      }
      .referenceItem-panel__label_active {
        // coorect active
        color: #000000;
      }
    }
    &-header {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      &-btns {
        display: flex;
        gap: 10px;
        &-btn {
          background-color: #fff;
        }
      }
    }
    &-label {
      position: relative;
      align-items: center;
      width: 100%;
      background: none;
      border: none;
      text-align: left;
      transition: color 0.2s linear;
      width: 100%;
      display: flex;
      cursor: pointer;
      color: #000000;
      @include nowrap;
      &:focus {
        outline: none;
      }
      > i {
        will-change: transform;
        transition: transform 0.3s linear;
      }
      &:first-child {
        i {
          margin-right: 10px;
        }
      }
      &:last-child {
        i {
          margin-left: 10px;
        }
      }
      &__icon {
        margin-right: 10px;
        &-minus {
          animation: 0.2s linear 0s normal none rot;
        }
        &-plus {
          animation: 0.2s linear 0s normal none rotRev;
        }
      }
      &__text {
        @include FSbaseBold;
        line-height: 24px;
      }
    }
    &__inner {
      overflow: hidden;
      will-change: height;
      transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    &-content {
      padding: 10px 10px 10px 20px;
      font-size: 16px;
      color: #000000;
      opacity: 0;
      transition: opacity 0.3s linear 0.18s;
      display: none;
      &__text {
        @include FSbaseReg;
        line-height: 16px;
      }
    }
  }
}
